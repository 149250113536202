import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiPaginationResponse } from '../../types/backend';
import {IOrder, IOrderPayment, IOrderPaymentResult } from '../../types/order';
import { IPassenger } from '../../types/passenger';
import { getCookie } from '../../utils/cookies';
import {BASE_URL, trimFields} from '../../utils/api';

const basicHeaders = { Accept: 'application/json', 'Content-type': 'application/json' };

interface ApiError {
  email?: string[];
}
interface ApiResponse<T = undefined> {
  status: boolean;
  message?: string;
  errors?: ApiError;
  data?: T;
}

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: ['Passenger'],
  endpoints: builder => ({
    createOrder: builder.mutation<IOrder, IOrder & { token?: string, user_id?: number }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        const refcode = getCookie('refcode') || undefined;
        return {
          url: `order`,
          method: 'POST',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}`, refcode },
          body,
        };
      },
    }),
    updateOrder: builder.mutation<IOrder, IOrder & { order_id: number; token?: string, user_id?: number }>({
      query: data => {
        const { order_id, token, ...body } = trimFields(data);
        const refcode = getCookie('refcode') || undefined;
        return {
          url: `order/${order_id}`,
          method: 'PUT',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}`, refcode },
          body,
        };
      },
    }),
    createPayment: builder.mutation<IOrderPaymentResult, IOrderPayment & { token: string }>({
      query: ({ token, order_id, type }) => {
        return {
          url: `order/payment/${order_id}`,
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          params: { order_id, type }
        };
      },
    }),
    getOrders: builder.query<ApiPaginationResponse<IOrder>, { token: string, user_id?: number, date_from?: string; date_to?: string }>({
      query: ({ token, user_id, date_from, date_to }) => ({
        url: 'order',
        headers: { Accept: 'text/html', Authorization: `Bearer ${token}` },
        params: { user_id, date_from, date_to }
      }),
    }),
    getOrder: builder.query<ApiPaginationResponse<IOrder>, { token: string, order_id?: number | string }>({
      query: ({ token, order_id }) => ({
        url: `order/${order_id}`,
        headers: { Accept: 'text/html', Authorization: `Bearer ${token}` },
      }),
    }),
    getOrderEx: builder.query<ApiPaginationResponse<IOrder>, { order_id?: string }>({
      query: ({ order_id }) => ({
        url: `get_order/${order_id}`,
        headers: { Accept: 'text/html' }
      }),
    }),
    saveOrderPassenger: builder.mutation<ApiResponse, IPassenger & { token: string }>({
      query: data => {
        const { token, ...body } = trimFields(data);
        return {
          url: `order/passenger/${body.id}`,
          method: 'PUT',
          headers: { ...basicHeaders, Authorization: `Bearer ${token}` },
          body,
        };
      },
      invalidatesTags: ['Passenger'],
    }),
  }),
});

export const { useCreateOrderMutation, useUpdateOrderMutation, useCreatePaymentMutation, useGetOrdersQuery, useGetOrderQuery, useGetOrderExQuery, useSaveOrderPassengerMutation } = orderApi;
