import clsx from 'clsx';
import {FC, useEffect, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IPassenger } from '../../../../types/passenger';
import PersonalCitizenship from '../PersonalCitizenship/PersonalCitizenship';
import {
    checkIsDateNotInFutureByTicket,
    checkIsDateNotInPast, checkIsDateValid,
    citizenshipPattern,
    combineValidators,
    documentPattern,
    maxLength, passportLocalPattern,
    passportPattern,
    required
} from '../../../../utils/formValidation';
import Input from '../../../Input/Input';
import Select from '../../../Input/Select/Select';
import Switch from '../../../Input/Switch/Switch';
import styles from './PersonalDocument.module.scss';
import { formatDate, onDateFieldChange } from '../../../../utils/order';
import {useSelector} from "react-redux";
import {bookingSelector} from "../../../../store/slices/booking";
import {getPassengerById} from "../../../../utils/adapters";

export const russiaDocumentOptions = [
  { label: 'Заграничный паспорт РФ', value: 'RF_PASSPORT' },
  { label: 'Внутренний паспорт РФ', value: 'RF_LOCAL_PASSPORT' },
  // { label: 'Иностранный документ', value: 'FOREIGN_DOCUMENT' },
];
const otherDocumentOptions = [
  { label: 'Паспорт', value: 'FOREIGN_DOCUMENT' }
];

export const citizenshipOptions = [
  { label: 'Россия', value: 'RU' },
  { label: 'Беларусь', value: 'BY' },
  { label: 'Казахстан', value: 'KZ' },
  { label: 'Украина', value: 'UA' },
  { label: 'Узбекистан',  value: 'UZ' },
  { label: 'Киргизия', value: 'KG' },
  { label: 'Таджикистан', value: 'TJ' }
];

interface Props {
  index?: number;
  passenger?: IPassenger;
  showHeader?: boolean;
  tabs50? : boolean;
}

const PersonalDocument: FC<Props> = ({ index, passenger, showHeader, tabs50 }) => {
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
    clearErrors,
    reset
  } = useFormContext();
  const id = (index ? index : (passenger && passenger.id > 0 ? passenger.id : 0)) + '_';
  const { trip, back } = useSelector(bookingSelector);
  const [backTime, setTime] = useState(0);
  const [hasValidity, setValidity] = useState(true);

  useEffect(() => {
    if (back && back?.segments_time?.[0]?.[1]) {
      setTime((back?.segments_time?.[0]?.[1] ?? 0));
    }
    else if (trip && trip?.segments_time?.[0]?.[1]) {
      setTime((trip?.segments_time?.[0]?.[1] ?? 0));
    }
    if (passenger) {
        const defaultValues = getPassengerById(passenger, index);
        reset(defaultValues);
        setValidity(defaultValues[`${id}hasValidity`]);
    }
  }, [trip, passenger]);

  const rules = {
    citizenship: {
        required,
        pattern: citizenshipPattern
    },
    document: { required },
    passport_id: {
      required,
      pattern: getValues(`${id}document`) === russiaDocumentOptions[0].value ? passportPattern : (getValues(`${id}document`) === russiaDocumentOptions[1].value ? passportLocalPattern : documentPattern)
    },
    validity: !!getValues(`${id}hasValidity`)
      ? {
          required: required,
          maxLength,
          validate: combineValidators([checkIsDateValid, checkIsDateNotInPast, checkIsDateNotInFutureByTicket], backTime),
        }
      : {
          required: false,
      },
  };

  const tabsStyle = tabs50 ? styles.tabs50 : styles.tabs;
  const passportType = getValues(`${id}document`) ?? russiaDocumentOptions[0].value;

  return (
    <fieldset className={styles.root}>
      {showHeader ? <div className={styles.heading}>Данные паспорта</div> : null}
      <div className={tabsStyle}>
        <div className={styles.wrapper}>
           <Controller
             name={`${id}citizenship`}
             control={control}
             rules={rules.citizenship}
             render={({ field }) => (
                <PersonalCitizenship
                  field={field}
                  label="Гражданство"
                  updateField={setValue}
                  errors={errors}
                />
             )}
          />
        </div>
        <div className={styles.wrapper}>
          <Controller
            name={`${id}document`}
            control={control}
            rules={rules.document}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value || ''}
                onChange={(e, value) => (setValue ? setValue(`${id}document`, value || '') : value)}
                error={!field.value ? errors[`${id}document`]?.message as string : ''}
                label="Документ"
                options={watch()[`${id}citizenship`] === 'RU' ? russiaDocumentOptions : otherDocumentOptions}
                className={clsx(styles.select, errors[`${id}document`]?.message ? styles.error : '')}
              />
            )}
          />
        </div>
        <div className={styles.wrapper}>
          <Controller
            name={`${id}passport_id`}
            control={control}
            rules={rules.passport_id}
            render={({ field }) => (
              <Input
                {...field}
                value={field.value || ''}
                error={errors[`${id}passport_id`]?.message as string}
                type={watch()[`${id}document`] !== otherDocumentOptions[0].value ? ('passport_' + passportType.trim().split('_')[1].toLowerCase()) : 'document'}
                label="Номер документа"
              />
            )}
          />
        </div>
        <div className={styles.wrapper}>
          <Controller
            name={`${id}validity`}
            control={control}
            rules={rules.validity}
            render={({ field }) => {
              return (
              <Input
                {...field}
                type={"date"}
                value={!field.value ? '' : field.value instanceof Date ? formatDate(field.value) : field.value}
                error={!!getValues(`${id}hasValidity`) ? errors[`${id}validity`]?.message as string : ''}
                label="Срок действия"
                onChange={(event) => {
                  clearErrors(`${id}validity`);
                  onDateFieldChange(event, field)
                }}
                disabled={ getValues(`${id}hasValidity`) !== undefined && !getValues(`${id}hasValidity`) }
              />
              /*<DateInput
                {...field}
                value={field.value || ''}
                onChange={(date: Date | null) => {
                  if (date) setValue(`${id}hasValidity`, true);
                  else setValue(`${id}hasValidity`, false);
                  field.onChange(date);
                }}
                id="validity"
                error={errors[`${id}validity`]?.message as string}
                formatPattern="dd.MM.yyyy"
                placeholder="ДД.ММ.ГГГГ"
                label="Срок действия"
                disablePast
                disabled={!getValues(`${id}hasValidity`)}
              />*/
            )}}
          />

          <Controller
            name={`${id}hasValidity`}
            control={control}
            render={({ field }) => (
              <Switch
                {...field}
                onChange={e => {
                  if (!e.currentTarget.checked) {
                      setValue(`${id}validity`, '');
                      setValidity(false);
                  } else {
                      setValidity(true);
                  }
                  field.onChange(e);
                }}
                checked={ field.value || false }
                label="Срок действия"
              />
            )}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default PersonalDocument;
