import { ChangeEvent, forwardRef, FunctionComponent, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './RadioInput.module.scss';
import { ErrorType } from '../Input';

type RadioInputType = {
  name: string;
  label?: string;
  description?: string;
  children?: ReactNode;
  className?: string;
  value?: string | number | readonly string[];
  checked?: boolean;
  error?: ErrorType;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RadioInput = forwardRef<HTMLInputElement, RadioInputType>(
  ({ label, name, description, value, checked, error, className, onChange }, ref) => {
    return (
      <div className={clsx(styles.root, className)}>
        <input
          onChange={onChange}
          name={name}
          id={name + value}
          value={value}
          type="radio"
          className={styles.input}
          checked={checked}
          ref={ref}
        />
        <label htmlFor={name + value} className={clsx(styles.label, error ? styles.error : '')}>
          <div className={styles.column}>
            {label}
            {description ? <span className={styles.description}>{description}</span> : null}
          </div>
        </label>
      </div>
    );
  }
);

export default RadioInput;
