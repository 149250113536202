import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { Routes, Route, useNavigationType, useNavigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { deleteCookie, getCookie, setCookie } from './utils/cookies';
import { useAppDispatch } from './store/store';
import { saveToken, saveUser, userSelector } from './store/slices/user';
import { useLazyGetPersonalInfoQuery, useVerifyEmailMutation } from './store/api/userService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Modals from './components/Modals';
import CookiesBanner from './components/CookiesBanner/CookiesBanner';
import { retry } from './utils/componentLoader';

const Home = lazy(() => retry(() => import('./pages/Home')));
const SearchResult = lazy(() => retry(() => import('./pages/SearchResult')));
const OrderDetailsPage = lazy(() => retry(() => import('./pages/LK/OrderDetailsPage/OrderDetailsPage')));
const OrdersPage = lazy(() => retry(() => import('./pages/LK/OrdersPage/OrdersPage')));
const PassengersPage = lazy(() => retry(() => import('./pages/LK/PassengersPage/PassengersPage')));
const AddPassengerPage = lazy(() => retry(() => import('./pages/LK/AddPassengerPage/AddPassengerPage')));
const PersonalPage = lazy(() => retry(() => import('./pages/LK/PersonalPage/PersonalPage')));
const BookingPage = lazy(() => retry(() => import('./pages/BookingPage')));
const BuyPage = lazy(() => retry(() => import('./pages/BuyPage')));
const NotFound = lazy(() => retry(() => import('./pages/NotFound/NotFound')));
const BadGateway = lazy(() => retry(() => import('./pages/BadGateway/BadGateway')));
const PayStub = lazy(() => retry(() => import('./pages/PayStub')));
const OrderPaymentSuccess = lazy(() => retry(() => import('./pages/LK/OrderPaymentSuccess/OrderPaymentSuccess')));
const OrderPaymentFail = lazy(() => retry(() => import('./pages/LK/OrderPaymentFail/OrderPaymentFail')));
const SafetyReturnPolicy = lazy(() => retry(() => import('./pages/Policies/SafetyReturnPolicy/SafetyReturnPolicy')));
const ConfedentialPolicy = lazy(() => retry(() => import('./pages/Policies/ConfedentialPolicy/ConfedentialPolicy')));
const Rules = lazy(() => retry(() => import('./pages/Policies/Rules/Rules')));
const BlogPosts = lazy(() => retry(() => import('./pages/Blog/BlogPosts/BlogPosts')));
const BlogPost = lazy(() => retry(() => import('./pages/Blog/BlogPost/BlogPost')));
const Tariffs = lazy(() => retry(() => import('./pages/Tariffs/Tariffs')));
const Help = lazy(() => retry(() => import('./pages/Help/Help')));
const Feedback = lazy(() => retry(() => import('./pages/Feedback/Feedback')));
const PartnershipMainPage = lazy(() => retry(() => import('./pages/Partnership/Partnership')));
const PartnershipPage = lazy(() => retry(() => import('./pages/LK/PartnershipPage/PartnershipPage')));
const PartnershipLinkDetails = lazy(() =>
  retry(() => import('./components/Partnership/PartnershipLinkDetails/PartnershipLinkDetails'))
);
const registerText = {
  header: 'Создать пароль',
  text: `Регистрация почти завершена, осталось создать пароль для аккаунта`,
  button: `Создать`,
};
const recoverText = {
  header: 'Сменить пароль',
  text: `Введите новый пароль`,
  button: `Сохранить`,
};
const errorText = {
  header: 'Неверная ссылка',
  text: `Некорректная ссылка. Получите ссылку заново`,
  button: '',
  isError: true,
};
import { partnerApi } from './store/api/partnerService';
import { useSelector } from 'react-redux';
import { useGetScriptsQuery } from './store/api/settingsService';
import { b64_to_utf8 } from './utils/adapters';
import { setModalText, updateModal } from './store/slices/modal';

const REACT_APP_REF_LINK_MAX_AGE = Number(process.env.REACT_APP_REF_LINK_MAX_AGE) || 3600 * 24 * 7;

function App() {
  const href = useLocation();
  const pathname = href.pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const action = useNavigationType();
  const { token } = useSelector(userSelector);
  const [getPersonalInfo] = useLazyGetPersonalInfoQuery();
  const [setSubscriber] = partnerApi.useSetSubscriberMutation();
  const { data: scriptsData } = useGetScriptsQuery({});
  const [verifyEmail] = useVerifyEmailMutation();

  const verifyEmailFromData = (email: string, code: string) => {
    const isRegister = pathname.includes('register');
    const text = isRegister ? registerText : recoverText;
    if (!code || !email) {
      dispatch(setModalText(errorText));
      dispatch(updateModal({ success: true }));
      return;
    }
    verifyEmail({ email, code })
      .unwrap()
      .then(data => {
        if (!data || !data.token || !data.status) {
          dispatch(setModalText(errorText));
          dispatch(updateModal({ success: true }));
          navigate('/', { replace: true });
          return;
        }
        localStorage.setItem('temp_token', data.token);
        dispatch(setModalText(text));
        dispatch(updateModal({ createPassword: true }));
        navigate('/', { replace: true });
      })
      .catch(() => {
        dispatch(setModalText(errorText));
        dispatch(updateModal({ success: true }));
        navigate('/', { replace: true });
      });
  };

  const processLink = useCallback(() => {
    if (pathname.startsWith('/ref_link/')) {
      const link = pathname.split('/')?.[2];
      if (!link) return;
      setSubscriber({ token, link })
        .unwrap()
        .then(data => {
          const refcode = data.refcode;
          const maxAge = data['max-age'] || REACT_APP_REF_LINK_MAX_AGE;
          if (!refcode) return;
          setCookie('refcode', refcode, maxAge);
        })
        .finally(() => {
          navigate('/');
        });
    }

    if (pathname.startsWith('/register')) {
      if (href.search.indexOf('?code=') > -1 && href.search.indexOf('&email=') > -1) {
        const verify = href.search.split('?code=')[1].trim();
        const separate = verify.split('&email=');
        const code = separate[0].trim();
        const email = separate[1].trim();
        verifyEmailFromData(email, code);
      }
    }

    if (pathname.indexOf('recover') > -1) {
      if (href.search.indexOf('verify=') > -1) {
        const verify = href.search.split('verify=')[1].trim();
        if (verify.length > 0) {
          const verifyData = atob(verify).split('|');
          if (verifyData?.length > 0) {
            const code = verifyData[0].split('=')[1].trim();
            const email = verifyData[1].split('=')[1].trim();
            verifyEmailFromData(email, code);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    processLink();
  }, []);

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action]);

  useEffect(() => {
    const logout = () => {
      deleteCookie('token');
      //if (href.pathname.match(/^\/lk/) || href.pathname.search(/code=/)) navigate('/');
      if (
        href.pathname.match(/^\/lk/) &&
        !(href.pathname.indexOf('/orders/success') > 0 || href.pathname.indexOf('/orders/fail') > 0)
      ) {
        navigate('/');
      }
    };
    const token = getCookie('token');
    if (!token) return logout();
    dispatch(saveToken({ token }));
    getPersonalInfo({ token })
      .unwrap()
      .then(user => {
        dispatch(saveUser({ user }));
      })
      .catch(() => logout());
  }, []);

  const initializeScript = (node: HTMLElement, code?: string) => {
    const script = document.createElement('div');
    script.innerHTML = code ? b64_to_utf8(code).trim() : '';
    if (script.childNodes && script.childNodes.length > 0) {
      script.childNodes.forEach(element => {
        node.append(element);
        if (element.nodeName.toLowerCase() === 'script' && typeof element.textContent === 'string') {
          eval(element.textContent.trim());
        }
      });
    }
  };

  useEffect(() => {
    if (scriptsData && scriptsData.data) {
      initializeScript(document.head, scriptsData.data.header); // scripts header
      initializeScript(document.body, scriptsData.data.footer); // scripts footer
    }
  }, [scriptsData]);

  return (
    <HelmetProvider>
      <ToastContainer />
      <Modals />
      <CookiesBanner />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>Бронирование авиабилетов для визы</title>
                <meta
                  name="description"
                  content="Бронирование Авиабилетов для визы без оплаты билета. Бронь билета без оплаты за 1190 рублей. Официальная бронь. Онлайн бронирование билетов для визы"
                />
              </Helmet>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/register"
          element={
            <Suspense fallback={null}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/ref_link/:hash"
          element={
            <Suspense fallback={null}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/password-recover"
          element={
            <Suspense fallback={null}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/search"
          element={
            <Suspense fallback={null}>
              <SearchResult />
            </Suspense>
          }
        />
        <Route
          path="/booking"
          element={
            <Suspense fallback={null}>
              <BookingPage />
            </Suspense>
          }
        />
        <Route
          path="/buying"
          element={
            <Suspense fallback={null}>
              <BuyPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/profile"
          element={
            <Suspense fallback={null}>
              <PersonalPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/orders"
          element={
            <Suspense fallback={null}>
              <OrdersPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/orders/success/:id"
          element={
            <Suspense fallback={null}>
              <OrderPaymentSuccess />
            </Suspense>
          }
        />
        <Route
          path="/lk/orders/fail/:id"
          element={
            <Suspense fallback={null}>
              <OrderPaymentFail />
            </Suspense>
          }
        />
        <Route
          path="/lk/orders/:id"
          element={
            <Suspense fallback={null}>
              <OrderDetailsPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/passengers"
          element={
            <Suspense fallback={null}>
              <PassengersPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/passengers/:id"
          element={
            <Suspense fallback={null}>
              <AddPassengerPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/partnership"
          element={
            <Suspense fallback={null}>
              <PartnershipPage />
            </Suspense>
          }
        />
        <Route
          path="/lk/partnership/:id"
          element={
            <Suspense fallback={null}>
              <PartnershipLinkDetails />
            </Suspense>
          }
        />
        <Route
          path="/safety_return_policy"
          element={
            <Suspense fallback={null}>
              <SafetyReturnPolicy />
            </Suspense>
          }
        />
        <Route
          path="/confedential_policy"
          element={
            <Suspense fallback={null}>
              <ConfedentialPolicy />
            </Suspense>
          }
        />
        <Route
          path="/blog"
          element={
            <Suspense fallback={null}>
              <BlogPosts />
            </Suspense>
          }
        />
        <Route
          path="/blog/:id"
          element={
            <Suspense fallback={null}>
              <BlogPost />
            </Suspense>
          }
        />
        <Route
          path="/rules"
          element={
            <Suspense fallback={null}>
              <Rules />
            </Suspense>
          }
        />
        <Route
          path="/tariff"
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>Тариф и цена на бронирование купитрип/ купитрип бронь/</title>
                <meta
                  name="description"
                  content="Цена тарифа авиабилетов для визы без оплаты билета. Стоимость тарифа для брони 1190 рублей. Официальная бронь. Тариф купитрип"
                />
              </Helmet>
              <Tariffs />
            </Suspense>
          }
        />
        <Route
          path="/help"
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>Сколько стоят услуги купитрип?</title>
                <meta
                  name="description"
                  content="Популярные вопросы к сервису купитрип. Сколько стоят услуги купитрип. Можно ли вернуть бронь купитрип? Официальная бронь."
                />
              </Helmet>
              <Help />
            </Suspense>
          }
        />
        <Route
          path="/feedback"
          element={
            <Suspense fallback={null}>
              <Helmet>
                <title>Купитрип бронирование, отзывы</title>
                <meta
                  name="description"
                  content="Отзывы о сервисе купитрип. Бронирование авиабилетов купитрип отзывы. Сколько стоят услуги бронирования отзывы. Купитрип купить авиабилет отзывы."
                />
              </Helmet>
              <Feedback />
            </Suspense>
          }
        />
        <Route
          path="/partnership"
          element={
            <Suspense fallback={null}>
              <PartnershipMainPage />
            </Suspense>
          }
        />
        <Route
          path="/pay_stub"
          element={
            <Suspense fallback={null}>
              <PayStub />
            </Suspense>
          }
        />
        <Route
          path="/404"
          element={
            <Suspense fallback={null}>
              <NotFound />
            </Suspense>
          }
        />
        <Route
          path="/502"
          element={
            <Suspense fallback={null}>
              <BadGateway />
            </Suspense>
          }
        />
        <Route
          path="/*"
          element={
            <Suspense fallback={null}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </HelmetProvider>
  );
}
export default App;
