import { useState } from 'react';
import Button from '../Button';
import styles from './CookiesBanner.module.scss';

const CookiesBanner = () => {
  const [isAccepted, setIsAccepted] = useState((localStorage.getItem('cookie_banner') !== null ? true : false));

  const handleAccept = () => {
    setIsAccepted(true);
    localStorage.setItem('cookie_banner', '1');
  }

  if (isAccepted) return null;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <span className={styles.text}>
          Продолжая использовать наш сайт, вы соглашаетесь с правилами сбора информации и использования cookie
        </span>
        <Button className={styles.button} onClick={handleAccept}>
          Хорошо
        </Button>
      </div>
    </div>
  );
};
export default CookiesBanner;
